import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { colors } from '../theme';
import ButtonLink from './Buttons';

const BgContainer = styled.div`
  background-color: ${colors.blue.footerBackground};
  border-color: ${colors.blue.footerBackground};
  color: ${colors.white};
  padding: 7rem 4rem;
`;

const Wrapper = styled.div`
  position: relative;
`;

const EmptySpace = styled.div`
  height: 6.4rem;
`;

const FormWrapper = styled.form`
  color: ${colors.white};
`;

const InputSection = styled.input`
  margin-bottom: 1rem;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  width: 100%;
  background-color: ${colors.gray.headerBackground};
  border: 1px solid ${colors.gray.formBorder};
  color: ${colors.gray.pTagsColor};
  font-size: 1.4rem;
  height: inherit;
  line-height: 1.5;
  padding: 0.8rem 2.2rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
`;

const TextAreaSection = styled.textarea`
  margin-bottom: 1rem;
  width: 100%;
  background-color: ${colors.gray.headerBackground};
  border: 1px solid ${colors.gray.formBorder};
  color: ${colors.gray.pTagsColor};
  font-size: 1.4rem;
  height: inherit;
  line-height: 1.5;
  padding: 0.8rem 2.2rem;
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  background-image: none;
  border: 0px solid transparent;
  font-weight: bold;
  font-size: 1.3rem;
  line-height: 1.5;
  padding: 0.8rem 2.4rem;
  color: ${colors.white};
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : colors.green.greenButton};
  border-color: transparent;
  width: ${(props) => (props.fullwidth ? '100%' : '')};
  cursor: pointer;
`;
const LableSection = styled.span`
  margin-bottom: 2rem;
  color: ${colors.gray.veryLight};
  font-size: 14px;
  padding: 0 2rem;
`;

const InpuytWrapper = styled.div`
  padding: 2.5rem;
  background-color: ${colors.white};
  border: 1px dashed ${colors.white};
  color: #aaa;
  margin-bottom: 16px;
`;

const FormTitle = styled.p`
  color: ${colors.white};
  margin-bottom: 1rem;
`;

const FormsConsualtant = () => {
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [files, setFiles] = useState([]);
  const [formValues, setFormValues] = useState({
    name: '',
    mobile: '',
    phone: '',
    age: '',
    sex: '',
    email: '',
    filenumber: '',
    history: '',
    drugname: '',
    files: '',
  });

  const fileInput = useRef();

  const resetForm = (e) => {
    e.preventDefault();
    setSubmitted(false);
    setIsSubmitted(false);
  };

  const encode = (data) => {
    const formData = new FormData();
    for (const key of Object.keys(data)) {
      if (key === 'files') {
        formData.append(key, data[key][0]);
      } else {
        formData.append(key, data[key]);
      }
    }
    return formData;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    fetch('/', {
      method: 'POST',
      // headers: { 'Content-Type': 'multipart/form-data; boundary=random' },
      body: encode({ 'form-name': 'contact', ...formValues }),
    })
      .then(() => setSubmitted(true))
      .catch((error) => setError(true));
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  return (
    <BgContainer>
      <Wrapper>
        <EmptySpace></EmptySpace>
        {!submitted ? (
          <FormWrapper
            method="POST"
            onSubmit={handleSubmit}
            name="contact"
            data-netlify="true"
            enctype="multipart/form-data"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            {error && <FormTitle>{error}</FormTitle>}
            <FormTitle>نام و نام خانوادگی</FormTitle>
            <InputSection
              type="text"
              name="name"
              required
              onChange={handleChange}
            />
            <FormTitle>شماره ی تلفن همراه</FormTitle>
            <InputSection
              type="text"
              name="phone"
              required
              onChange={handleChange}
            />
            <FormTitle>آدرس ایمیل</FormTitle>
            <InputSection
              type="email"
              name="email"
              onChange={handleChange}
              required
            />
            <FormTitle>سن</FormTitle>
            <InputSection
              type="number"
              name="age"
              required
              onChange={handleChange}
            />
            <FormTitle>جنسیت</FormTitle>
            <InputSection
              type="text"
              name="sex"
              required
              onChange={handleChange}
            />
            <FormTitle>شماره پرونده</FormTitle>
            <InputSection
              type="text"
              name="filenumber"
              onChange={handleChange}
            />
            <FormTitle>
              اگر سابقه ی بیماری خاصی دارید نام آن را بنویسید
            </FormTitle>
            <InputSection type="text" name="history" onChange={handleChange} />
            <FormTitle>
              در صورتی که داروی خاصی مصرف می کنید نام آن را وارد کنید
            </FormTitle>
            <InputSection type="text" name="drugname" onChange={handleChange} />
            <FormTitle>موضوع</FormTitle>
            <InputSection
              type="text"
              name="topic"
              onChange={handleChange}
              required
            />
            <FormTitle>پیام</FormTitle>
            <TextAreaSection
              rows="12"
              name="message"
              onChange={handleChange}
              required
            ></TextAreaSection>
            <FormTitle>ارسال مدارک</FormTitle>
            <InpuytWrapper>
              <LableSection>فایل ها را به اینجا بکشید</LableSection>
              <ButtonLink
                isButton
                onClick={(e) => {
                  e.preventDefault();
                  fileInput.current.click();
                }}
                title={'فایل ها را انتخاب نمایید'}
                bgColor={colors.blue.captionBlue}
              />
              <input
                type="file"
                name="files"
                multiple
                ref={fileInput}
                accept="image/jpeg,image/gif,image/png,application/pdf"
                onChange={(e) => {
                  setFiles([...fileInput.current.files]);
                  setFormValues({
                    ...formValues,
                    files: fileInput.current.files,
                  });
                }}
                style={{ display: 'none' }}
              ></input>
              {files.length > 0 &&
                files.map((file, i) => <p key={i}>{file.name}</p>)}
            </InpuytWrapper>
            <input type="hidden" name="form-name" value="contact" />
            {!isSubmitting ? (
              <Button type="submit" bgColor={colors.blue.captionBlue}>
                ارسال
              </Button>
            ) : (
              <p>لطفا صبر کنید...در حال ارسال</p>
            )}
          </FormWrapper>
        ) : (
          <>
            <FormTitle>با تشکر به زودی با شما تماس خواهیم گرفت</FormTitle>
            <Button onClick={resetForm} bgColor={colors.blue.captionBlue}>
              ارسال مجدد
            </Button>
          </>
        )}
      </Wrapper>
    </BgContainer>
  );
};

export default FormsConsualtant;
