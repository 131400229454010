import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import FormCheckUp from '../components/Form-checkup';

const FormPage = () => {
  return (
    <Layout>
      <FormCheckUp />
    </Layout>
  );
};
export default FormPage;
