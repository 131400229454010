import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Container } from 'styled-bootstrap-grid';
import { Grid, Cell, colors } from '../theme';
import ButtonLink from './Buttons';
import FormsConsualtant from './Forms-consualtant';
import ContentHeader from './Content-header';

const SectionLayout = styled.section`
  position: relative;
  background-color: ${colors.blue.formBg};
  border-color: ${colors.blue.formBg};
  color: ${colors.blue.formBg};
`;

const Wrapper = styled.div`
  padding: 5rem 0;
`;

const FormSection = styled.form`
  background-color: ${colors.blue.formBg};
  border-radius: 0px;
  color: ${colors.gray.pTagsColor};
  font-size: 14px;
  height: inherit;
  line-height: 1.5;
  padding: 7px 0 7px 22px;
`;

const InputSection = styled.input`
  margin-bottom: 1rem;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  width: 100%;
  background-color: ${colors.white};
  border: 1px solid ${colors.white};
  color: ${colors.gray.pTagsColor};
  font-size: 14px;
  height: inherit;
  line-height: 1.5;
  padding: 0.8rem 2.2rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
`;

const LableSection = styled.span`
  margin-bottom: 2rem;
  color: ${colors.gray.veryLight};
  font-size: 14px;
  padding: 0 2rem;
`;

const InpuytWrapper = styled.div`
  padding: 2.5rem;
  background-color: ${colors.white};
  border: 1px dashed ${colors.white};
  color: #aaa;
  margin-bottom: 16px;
`;

const FormTitle = styled.p`
  color: ${colors.white};
  margin-bottom: 1rem;
`;

const DetailsText = styled.div`
  text-align: justify;
  padding: 1rem 0;
  color: ${colors.white};
  font-weight: bold;
`;
const ActualButton = styled.button`
  display: flex;
  justify-content: center;
  background-image: none;
  border: 0px solid transparent;
  font-weight: bold;
  font-size: 1.3rem;
  line-height: 1.5;
  padding: 0.8rem 2.4rem;
  color: ${colors.white};
  background-color: ${(props) =>
    props.backgoundcolor ? props.backgoundcolor : colors.green.greenButton};
  border-color: transparent;
  width: ${(props) => (props.fullwidth ? '100%' : '')};
`;

const FormCheckUp = () => {
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [files, setFiles] = useState([]);
  const [formValues, setFormValues] = useState({
    name: '',
    mobile: '',
    phone: '',
    age: '',
    sex: '',
    email: '',
    filenumber: '',
    history: '',
    drugname: '',
  });

  const fileInput = useRef();

  const resetForm = (e) => {
    e.preventDefault();
    setSubmitted(false);
  };

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]),
      )
      .join('&');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'onlinevisit', ...formValues }),
    })
      .then(() => setSubmitted(true))
      .catch((error) => setError(true));
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  return (
    <SectionLayout>
      <Container>
        <Wrapper>
          <ContentHeader
            titleColor={colors.white}
            subtitleColor={colors.yellow.hoverFooter}
            title={' فرم درخواست ویزیت آنلاین'}
            subtitle={'برای دریافت ویزیت آنلاین فرم زیر را تکمیل نمایید:'}
          />

          {!submitted ? (
            <FormSection
              method="POST"
              onSubmit={handleSubmit}
              name="onlinevisit"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <DetailsText>
                بسته به تعداد نوبت های آنلاین، ۴۸ تا ۷۲ ساعت پس از تکمیل فرم زیر
                و پرداخت ویزیت، دکتر محمود سامعی با شما تماس خواهد گرفت. لطفا پس
                از واریز مبلغ ویزیت به شماره کارت 8020 - 1137 - 8510 - 5057
                متعلق به محمود سامعی نزد بانک ایران زمین ؛ عکس فیش واریزی را به
                شماره ۰۹۳۵۹۳۵۹۹۴۷ ارسال نمایید؛ و ساعت تماس را دریافت نمایید.
              </DetailsText>
              {error && <FormTitle>{error}</FormTitle>}
              <FormTitle>نام و نام خانوادگی</FormTitle>
              <InputSection
                type="text"
                name="name"
                required
                onChange={handleChange}
              />
              <FormTitle>شماره ی تلفن همراه</FormTitle>
              <InputSection
                type="phone"
                name="mobile"
                required
                onChange={handleChange}
              />
              <FormTitle>شماره ی تلفن ثابت</FormTitle>
              <InputSection
                type="phone"
                name="phone"
                required
                onChange={handleChange}
              />
              <FormTitle>سن</FormTitle>
              <InputSection
                type="number"
                name="age"
                required
                onChange={handleChange}
              />
              <FormTitle>جنسیت</FormTitle>
              <InputSection
                type="text"
                name="sex"
                required
                onChange={handleChange}
              />
              <FormTitle>شماره پرونده</FormTitle>
              <InputSection
                type="text"
                name="filenumber"
                onChange={handleChange}
              />
              <FormTitle>
                اگر سابقه ی بیماری خاصی دارید نام آن را بنویسید
              </FormTitle>
              <InputSection
                type="text"
                name="history"
                onChange={handleChange}
              />
              <FormTitle>
                در صورتی که داروی خاصی مصرف می کنید نام آن را وارد کنید
              </FormTitle>
              <InputSection
                type="text"
                name="drugname"
                onChange={handleChange}
              />
              <FormTitle>ارسال فایل</FormTitle>
              <InpuytWrapper>
                <LableSection>فایل ها را به اینجا بکشید</LableSection>
                <ButtonLink
                  isButton
                  onClick={(e) => {
                    e.preventDefault();
                    fileInput.current.click();
                  }}
                  title={'فایل ها را انتخاب نمایید'}
                  bgColor={colors.blue.captionBlue}
                />
                <input
                  type="file"
                  name="files"
                  multiple
                  ref={fileInput}
                  accept="image/jpeg,image/gif,image/png,application/pdf"
                  onChange={(e) => {
                    setFiles([...fileInput.current.files]);
                  }}
                  style={{ display: 'none' }}
                ></input>
                {files.length > 0 &&
                  files.map((file, i) => <p key={i}>{file.name}</p>)}
              </InpuytWrapper>

              <FormTitle>قیمت: ۷۰,۰۰۰ تومان</FormTitle>
              <input type="hidden" name="form-name" value="onlinevisit" />
              <ActualButton bgColor={colors.blue.captionBlue}>
                ارسال درخواست
              </ActualButton>
            </FormSection>
          ) : (
            <>
              <FormTitle>با تشکر به زودی با شما تماس خواهیم گرفت</FormTitle>
              <ActualButton
                onClick={resetForm}
                bgColor={colors.blue.captionBlue}
              >
                ارسال مجدد
              </ActualButton>
            </>
          )}
        </Wrapper>
      </Container>
    </SectionLayout>
  );
};

export default FormCheckUp;
